<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            站内通知
        </div>
        <div class="vip-content-inner">
            <div class="vip-table-wrap">
                <table class="vip-table stripe">
                    <thead>
                    <tr>
                        <td>类型</td>
                        <td>标题</td>
                        <td>时间</td>
                        <td>状态</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="cursorPointer" @click="goXXdetail(item.id)" v-for="(item,index) in NotifyList" :class="item.status==1?'read':''" :key="index">
                        <td>{{ item.categoryName }}</td>
                        <td>
                            <div href="" class="link">{{item.title}}</div>
                        </td>
                        <td>{{ item.createTime }}</td>
                        <td>{{item.status==1?'已读':'未读'}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="custom-pages">
                <el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					@prev-click="handlePrevChange"
					@next-click="handleNextChange"
					:current-page="current"
					:page-sizes="[10, 50, 100]"
					:page-size="size"
					layout="total,  prev, pager, next,sizes, jumper"
					:total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {noticeList,messageRecordPage} from "@/api/account";

export default {
    name: "list",
	data(){
    	return{
			current:1,
			size:10,
			total:0,
			NotifyList:[],

		}
	},
	mounted(){
		this.getnoticeList();
	},
	methods:{
		//分页每页下拉显示数据
		handleSizeChange(size) {
			this.size=size;
			this.getnoticeList();
		},
		//分页点击第几页
		handleCurrentChange(currentPage){
			this.current = currentPage;
			this.getnoticeList();
		},
		handlePrevChange(e){ // 上一页
			this.current = e;
			this.getnoticeList();
		},
		handleNextChange(e){ // 下一页
			this.current = e;
			this.getnoticeList();
		},
		//去消息通知详情
		goXXdetail(id){
			// query传参数跳转用path (/BrandDetail类似get,url后面会显示参数) params传参数跳转用name (类似post)
			this.$router.push({path:'/MessageDetail',query: {id:id}}) //
		},

		//  -------------------------接口请求
		// 获取消息通知列表
		getnoticeList(){
			// let userId=JSON.parse(localStorage.getItem('userInfo')).userId
            let param={
                current:this.current,
                size:this.size,
            }
            messageRecordPage(param).then((res) => {
				if (res.data.code==200) {
					this.NotifyList=res.data.data.records
					this.total=Number(res.data.data.total)
				}

			})
		},

	},

}
</script>

<style scoped>

</style>
